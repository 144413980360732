var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
        'page-subtitle': true,
        'd-flex': true,
        'align-content-center': true,
        'mb-3': !_vm.noBottomMargin,
        dark: _vm.dark,
    }},[(_vm.show)?_c('h3',{class:{
            overline: true,
            'py-2': true,
            'px-3': true,
            'text--disabled': true,
        }},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_c('v-skeleton-loader',{staticClass:"mt-1 mb-2",attrs:{"type":"sentences","min-width":"250","max-width":"350"}}),_c('v-spacer'),_vm._t("right")],2)}
var staticRenderFns = []

export { render, staticRenderFns }